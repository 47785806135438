@import url('https://fonts.googleapis.com/css?family=Lora:400,700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Quicksand:wght@500&display=swap');

html,
body {
  margin: 0 auto;
  max-width: 1580px;

  font-family: 'Quicksand', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  background-color: #d31145;
  overflow-x: hidden;
}
p {
  font-size: 22px;
}
