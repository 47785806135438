@import url(https://fonts.googleapis.com/css?family=Lora:400,700&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Quicksand:wght@500&display=swap);
html,
body {
  margin: 0 auto;
  max-width: 1580px;

  font-family: 'Quicksand', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  background-color: #d31145;
  overflow-x: hidden;
}
p {
  font-size: 22px;
}

.button {
  margin: 0 0.25rem;
  border: none;
  background: white;
  padding: 0.25rem;
  font-size: 0.875rem;
  color: #007c6c;
  font-weight: bold;
}

.selected-image {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
  align-items: center;
}

.selected-image__wrapper {
  position: relative;
  margin: 1rem auto 0;
}

.selected-image__wrapper img {
  position: relative;
  width: 100%;
}

.results__container {
  margin: 0 auto;
}

.selected-image__processing {
  flex-basis: 100%;
}

.selected-image__image {
  position: relative;
}

.selected-image__overlay {
  position: absolute;
  left: 0;
  top: 0;
}

@media only screen and (min-width: 768px) {
  .selected-image {
    padding-top: 4px;
  }

  .selected-image__wrapper,
  .selected-image .results__container {
    width: 50%;
    margin: 0;
  }

  .results__container p {
    padding: 0 1rem;
  }
}

.results p {
  font-size: 1.25rem;
}

.results__wrapper {
  display: flex;
  margin-top: 1rem;
}

.results__emoji {
  color: #d31145;
}

.results__text {
  width: 300px;
}

.results__emoji svg {
  margin-right: 0.75rem;
}

.gallery {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
}

.gallery__container {
  margin-top: 2rem;
  width: 100%;
  min-height: 500px;
}

.gallery__container h2 {
  text-align: center;
}

.gallery__image-wrapper {
  position: relative;
  margin: 1rem;
  width: 150px;
  height: 120px;
}

.gallery__photo {
  width: 100%;
  opacity: 0.5;
  cursor: pointer;
  border: 1px solid #fff;
  border-radius: 2px;
}

.gallery__photo-delete {
  position: absolute;
  top: 4px;
  left: 4px;
  color: rgba(255, 109, 109, 0.762);
  cursor: pointer;
}

.gallery__photo--selected {
  opacity: 1;
}

.camera {
  width: 100%;
  margin-top: 1rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;
}
p.scroll_down
{
	font-size: 20px;
    color: white;
}
.camera__wrapper {
  position: relative;
  width: 80%;
  max-width: 680px;
  margin: 0 1rem 1rem;
}

.camera__wrapper video {
  border: 3px solid #fff;
  border-radius: 3px;
}

.camera__button-container {
  margin-top: 1rem;
  margin-bottom: 2rem;
}

.camera__button--snap {
  height: 50px;
  width: 50px;
  border-radius: 50%;
  font-size: 20px;
  cursor: pointer;
}

.webcam-overlay {
  position: absolute;
  left: 0;
  top: 0;
}

.webcam-overlay--hidden {
  display: none;
}

.results__container {
  background-color: #fff;
  width: 100%;
  padding-top: 1rem;
  min-height: 250px;
  display: flex;
  justify-content: center;
}

.results__wrapper {
  padding: 1rem 0 0 1rem;
}

@media only screen and (min-width: 768px) {
  .camera__wrapper {
    width: 100%;
    margin: 0 1rem 1rem;
  }

  .results__wrapper {
    padding: 0;
  }
}

.App {
  background-color: #d31145;
  min-height: 100vh;
}

.App header {
  background: white;
}

.App__header {
  display: flex;
  max-width: 960px;
  margin: 0 auto;
  padding: 0.5rem;
  justify-content: space-between;
  align-items: center;
  height: 4em;
}

.App__switcher {
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-size: 22px;
}

.App__switcher-switch {
  margin: 0 0.25rem;
}

