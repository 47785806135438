.results p {
  font-size: 1.25rem;
}

.results__wrapper {
  display: flex;
  margin-top: 1rem;
}

.results__emoji {
  color: #d31145;
}

.results__text {
  width: 300px;
}

.results__emoji svg {
  margin-right: 0.75rem;
}
